import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import './all.scss';
import OwlCarousel from'react-owl-carousel2';
import { Context } from './Context';

var decode = require('unescape');


export default class TrendingPosts extends React.Component {

    render() {
        return (
          <Context.Consumer>
            {({ lang }) => (
              <StaticQuery
                query={graphql`
                  query {
                    allWordpressPost(sort: {order: DESC, fields: date}) {
                        edges {
                          node {
                            id
                            title
                            excerpt
                            acf {
                              post_type
                            }
                            categories {
                              name
                              slug
                            }
                            better_featured_image {
                              source_url
                            }
                            author {
                              name
                              slug
                              avatar_urls {
                                wordpress_48
                              }
                            }
                            date(formatString: "MMMM DD, YYYY")
                            slug
                            wordpress_site
                          }
                        }
                      }
                  }
                `}
                render={data => (
                  <aside className="widget widget_tranding_post">
                    <h3 className="widget-title">{lang=='en' ? 'TRENDING Posts' : 'الوظائف الشائعة'}</h3>
                    <OwlCarousel options={{
                            items: 1,
                            nav: false,
                            rewind: true,
                            autoplay: true
                        }} >
                        {data.allWordpressPost.edges.filter(({node: post}) => post.wordpress_site===lang)
                        .map(({ node: post }, index) => (
                            
                            (index < 7 && post.acf.post_type.includes("Trending") && (
                                <div class="trnd-post-box">
                                    <div class="post-cover">
                                        <Link to={lang+ "/" + decodeURI(post.slug) + "/"} title={post.title}>
                                            <img src={ post.better_featured_image ?  post.better_featured_image.source_url : "https://placehold.it/477x500"} alt="Tranding Post" />
                                        </Link>
                                    </div>
                                    {post.categories && (
                                      <span class="post-category">
                                        <a href="#" title={decode(post.categories[0].name)}>{decode(post.categories[0].name)}</a>
                                      </span>
                                    )}
                                    
                                    <h3 class="post-title">
                                      <Link to={lang+ "/" + decodeURI(post.slug) + "/"} title={post.title}><span dangerouslySetInnerHTML={{ __html: post.title }}></span></Link>
                                    </h3>
                                </div>
                            ))
                        ))}
                    </OwlCarousel>
                  </aside>
                )}
              />
            )}
          </Context.Consumer>
        )
    }
}
