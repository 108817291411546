import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import PopularPosts from '../components/PopularPosts';
import CategoriesPosts from '../components/CategoriesPosts';
import InstagramSideWidget from '../components/InstagramSideWidget';
import FollowUsSocialSideWidget from '../components/FollowUsSocialSideWidget';
import NewsletterSieWidget from '../components/NewsletterSieWidget';
import TagsSideWidget from '../components/TagsSideWidget';
import TrendingPosts from '../components/TrendingPosts';
import Head from '../components/Head'

const Category = props => {
  const { data, pageContext } = props
  const { edges: posts, totalCount } = data.allWordpressPost
  const { title: siteTitle } = data.site.siteMetadata
  const { name: category } = pageContext
  const title = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } in the “${category}” category`

  const seo =   {canonical: 'https://blog-test.halayalla.rocks/',
                  metadesc: "This is meta description for halayall blog",
                  seoTitle: "Hala Yalla Blog | " + category,
                  metakeywords: "Hala yalla blog, sports and events in Saudi arabia, Download hala yalla app",
                  opengraph_title: "Hala Yalla Blog | " + category,
                  opengraph_description: "Organize sports and events in Saudi Arabia",
                  opengraph_image : {
                    source_url: "https://blog-test.halayalla.rocks/wp-content/uploads/2020/01/79907400_2723039161249533_7604513295656910987_n.jpg"
                  },
                  twitter_title: "Hala Yalla Blog | " + category,
                  twitter_description: "Organize sports and events in Saudi Arabia",
                  twitter_image: {
                    source_url: "https://blog-test.halayalla.rocks/wp-content/uploa…907400_2723039161249533_7604513295656910987_n.jpg"
                  }
                }

  return (
    <Layout>
      <Head {...seo}/>
      <main className="site-main">
        <div className="container-fluid no-left-padding no-right-padding page-content blog-single">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-6 col-12 content-area">
                <PostList posts={posts} title={title} />
              </div>
              <div className="col-lg-4 col-md-6 col-12 widget-area">
                <PopularPosts />
                <CategoriesPosts />
                {/* <InstagramSideWidget /> */}
                <FollowUsSocialSideWidget />
                <NewsletterSieWidget />
                <TagsSideWidget />
                <TrendingPosts />
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost( filter: { categories: { elemMatch: { slug: { eq: $slug } } } } ) {
      totalCount
      edges {
        node {
          ...PostListField
        }
      }
    }
  }
`